<template>
  <div :class='pageClassByNavigationComponent'
    :style='minHeightStyle'>
    <component
      :is='pageNavigationComponent'
      :sidebar-links='sidebarConfigurations.sidebarLinks'
      :sidebar-page-title='sidebarConfigurations.sidebarPageTitle'
      :custom-background-stype='sidebarConfigurations.customBackgroundStyle' />
    <div class='px-4 lg:px-0 flex-grow relative'>
      <div class='py-8 lg:py-16'>
        <div class='flex flex-row gap-x-8 mb-4 lg:mb-8'>
          <h1 class='text-3xl font-bold'>Find ID (E-mail)</h1>
        </div>
        <div class='mb-6 w-full lg:w-96'>
          <label 
            class='uppercase text-sm text-gray-700'>
            First Name
          </label>
          <input
            v-model='firstName'
            placeholder='First Name'
            class='border py-1 px-2 rounded block w-full'>
        </div>
        <div class='mb-6 w-full lg:w-96'>
          <label 
            class='uppercase text-sm text-gray-700'>
            Last Name
          </label>
          <input
            v-model='lastName'
            placeholder='Last Name'
            class='border py-1 px-2 rounded block w-full'>
        </div>
        <div class='mb-6 w-full lg:w-96'>
          <label 
            class='uppercase text-sm text-gray-700'>
            Phone Number
          </label>
          <div>
            <vue-phone-number-input
              class='block w-full'
              v-model='phoneNumber'
              :valid-color='eventMainThemeColor'
              default-country-code='KR'
              @update='updatePhoneNumberInput' />
          </div>
        </div>
        <button
          class='mb-6 w-full lg:w-96 border py-3 text-center text-white text-sm uppercase bg-gray-900 block rounded-md'
          :class='disabledFindEventIdButtonClass'
          :disabled='disabledFindEventIdButton'
          @click='findEventLoginId'>
          Find ID (E-mail)
        </button>
        <div class='bg-gray-100 p-8 font-semibold'>
          {{ findLoginIdBoxText }}
          <ul class='list-disc list-inside mt-2'>
            <div v-for='(id, index) in  eventLoginIds'
              :key='`${id}-${index}`'>
              <li class='mb-1 font-normal'>{{ id }}</li>
            </div>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'

export default {
  name: 'FindLoginId',
  components: {
    VuePhoneNumberInput,
    'tabs-page-navigation': () => import('@/components/common/TabsPageNavigation'),
  },
  data () {
    return {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      isValidPhoneNumber: false,
      eventLoginIds: null,
    }
  },
  computed: {
    ...mapGetters('events',[
      'eventMainThemeColor',
    ]),
    pageClassByNavigationComponent () {
      return this.pageNavigationComponent === 'sidebar-page-navigation' ? 'flex flex-col lg:flex-row justify-between items-stretch gap-x-16' : ''
    },
    minHeightStyle () {
      let minHeight = 64 // size of the navbar
      return `min-height: calc(100vh - ${minHeight}px);`
    },
    pageNavigationComponent () {
      return 'tabs-page-navigation'
    },
    disabledFindEventIdButton () {
      return !this.firstName ||
             !this.lastName ||
             !this.isValidPhoneNumber
    },
    disabledFindEventIdButtonClass () {
      return this.disabledFindEventIdButton ? 'opacity-50 cursor-not-allowed' : ''
    },
    findEventLoginIdParams () {
      return {
        firstName: this.firstName,
        lastName: this.lastName,
        phoneNumber: this.phoneNumber,
      }
    },
    sidebarConfigurations () {
      return {
        sidebarLinks: [
          {
            'link': 'Login',
            'title': 'Go To Login',
            'actionType': 'open_internal_route'
          },
          {
            'link': 'FindLoginId',
            'title': 'Find Id(E-mail)',
            'actionType': 'open_internal_route'
          },
          {
            'link': 'ChangeLoginPassword',
            'title': 'Reset Password',
            'actionType': 'open_internal_route'
          },
        ],
        sidebarPageTitle: '',
        customBackgroundStyle: ''
      }
    },
    findLoginIdBoxText () {
      if (this.eventLoginIds) {
        if (this.eventLoginIds.length) {
          return 'Your ID(E-mail) is'
        } else {
          return "Can't find the ID(E-mail) Please try with different information."
        }
      } else {
        return 'Please input name and phone number to lookup your ID(E-mail).'
      }
    },
  },
  methods: {
    ...mapActions('users', [
      'getEventLoginId'
    ]),
    updatePhoneNumberInput (data) {
      this.phoneNumber = data.formatInternational
      this.isValidPhoneNumber = data.isValid
    },
    findEventLoginId () {
      this.getEventLoginId(this.findEventLoginIdParams).then(resp => {
        this.eventLoginIds = resp
      })
    },
  },
}
</script>

<style lang='scss' scoped>
.background-style {
  background: transparent; 
  width: 100%;
}

.sidebar-navigation-links .router-link-active {
  @apply font-bold;
}

@media (min-width: 1024px) {
  .background-style {
    width: 20rem;
    height: auto;
  }
}    
</style>
